<!--
	@component:	ANZGROUPHEADLINE
	@desc:		This component is used to structure other components inside of it like options, buttons and inputs.
				Additionaly, the component handle the button state for child buttons.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2018-07-16
 -->
<template>
  <li class="list__item">
    <h3 v-if="data.Lanqkey01" class="list__item-headline" v-html="data.Lanqkey01"></h3>

    <ul v-if="data.GuiNodeCh" class="list">
      <NodeControls
        v-for="item in data.GuiNodeCh"
        :key="item.Xnodekey"
        :button-state="buttonState"
        :component="item.XcontrolChild"
        :data="item"
        :root-data="data"
        :update-node-key="updateNodeKey"
        @button-state="onChangeButtonState"
      ></NodeControls>
    </ul>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data" :class="{ 'debug__item--parent': !data.Lanqkey01 }"></DebugItem>
  </li>
</template>

<script>
import DebugItem from '@/components/DebugItem';

export default {
  name: 'ANZGROUPHEADLINE',

  components: {
    DebugItem
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    updateNodeKey: {
      type: String,
      required: true
    }
  },

  computed: {
    debug() {
      return this.$store.state.app.debug;
    }
  },

  data() {
    return {
      buttonState: {
        disabled: false
      }
    };
  },

  methods: {
    onChangeButtonState(state) {
      this.buttonState = state;
      this.$emit('button-state', this.buttonState);
    }
  }
};
</script>
